<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>list-bullets-1</title>
    <path d="M8.5,5H23a1,1,0,0,0,0-2H8.5a1,1,0,0,0,0,2Z" />
    <path d="M23,11H8.5a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" />
    <path d="M23,19H8.5a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" />
    <rect x="1" y="2.498" width="3" height="3" rx="0.5" ry="0.5" />
    <path
      d="M3.5,1.5h-2A1.5,1.5,0,0,0,0,3V5A1.5,1.5,0,0,0,1.5,6.5h2A1.5,1.5,0,0,0,5,5V3A1.5,1.5,0,0,0,3.5,1.5ZM4,5a.5.5,0,0,1-.5.5h-2A.5.5,0,0,1,1,5V3a.5.5,0,0,1,.5-.5h2A.5.5,0,0,1,4,3Z"
    />
    <rect x="1" y="10.498" width="3" height="3" rx="0.5" ry="0.5" />
    <path
      d="M3.5,9.5h-2A1.5,1.5,0,0,0,0,11v2a1.5,1.5,0,0,0,1.5,1.5h2A1.5,1.5,0,0,0,5,13V11A1.5,1.5,0,0,0,3.5,9.5ZM4,13a.5.5,0,0,1-.5.5h-2A.5.5,0,0,1,1,13V11a.5.5,0,0,1,.5-.5h2A.5.5,0,0,1,4,11Z"
    />
    <rect x="1" y="18.498" width="3" height="3" rx="0.5" ry="0.5" />
    <path
      d="M3.5,17.5h-2A1.5,1.5,0,0,0,0,19v2a1.5,1.5,0,0,0,1.5,1.5h2A1.5,1.5,0,0,0,5,21V19A1.5,1.5,0,0,0,3.5,17.5ZM4,21a.5.5,0,0,1-.5.5h-2A.5.5,0,0,1,1,21V19a.5.5,0,0,1,.5-.5h2A.5.5,0,0,1,4,19Z"
    />
  </svg>
</template>
